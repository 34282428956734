<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-group">
          <template v-slot:heading>
            <h1 v-if="!editTable" class='display-2'>Manajemen Aktivitas Tambahan</h1>
            <h1 v-else class='display-2'>Manajemen Aktivitas</h1>
          </template>
          <div class="d-flex flex-row pa-3">
            <div style="max-width:400px" class="d-flex justify-start pt-2">
              <v-btn medium :color="editTable ? 'primary' : 'error'" @click="edit()"><v-icon small>{{ this.editTable ? 'mdi-table-large' : 'mdi-file-excel' }}</v-icon> &nbsp; {{ this.editTable ? 'Lihat Tabel' : 'Edit Tabel'}}</v-btn>
              &nbsp;
              <v-btn medium color="warning white--text" @click="$router.back()"><v-icon small>mdi-arrow-left-bold</v-icon> &nbsp;Kembali</v-btn>
            </div>
            <v-spacer></v-spacer>
            <div style="max-width:400px" class="mx-5">
              <v-text-field v-if="!editTable" v-model="search" hide-details label="Cari" append-icon="mdi-magnify"></v-text-field>
            </div>
            <div style="max-width:400px" class="d-flex justify-end pt-2">
              <v-btn v-if="!editTable" medium color="primary" @click="tambah()"><v-icon small>mdi-plus</v-icon> &nbsp;Tambah Aktivitas</v-btn>
              <v-btn v-else medium color="teal white--text darken-2" @click="simpanEditTable()" :loading="loadingSimpanEditTable"><v-icon small>mdi-content-save</v-icon> &nbsp;Simpan</v-btn>
            </div>
          </div>
          <div id="example1">
            <hot-table v-show="editTable" ref="hotTableComponent" :settings="hotSettings"></hot-table>
          </div>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalKeseluruhan"
            :page.sync="page"
            :items-per-page.sync="rowPerPage"
            :footer-props="{'items-per-page-options':[5,10,15]}"
            class="pegawai-table"
            :loading="loading"
            v-show="!editTable"
          >
            <template v-slot:item.no="{item, index}">
              {{index+1}}
            </template>
            <template v-slot:item.skpd="{item}">
              <span v-if="item.kolok == 'ALL'">{{ item.kolok }}</span>
              <span v-else>{{ item.nama_skpd }}</span>
            </template>
            <template v-slot:item.waktu="{item}">
              {{ item.waktu }} menit
            </template>
            <template v-slot:item.aksi="{item}" style="border:4px solid black">
              <div class="d-flex flex-column">
                <v-btn @click="editItem(item)" x-small class="warning ma-1" dark><v-icon x-small>mdi-pencil</v-icon>&emsp; Edit</v-btn>
                <confirm-btn
                  width="auto"
                  title='Hapus Aktivitas'
                  :content="'Apakah Anda Yakin Akan Menghapus Aktivitas Ini: <br> <strong>' + item.nama_aktifitas + '</strong> ' "
                  :btnProps="{class:'ma-1', name:'hapus', color:'red', xSmall:true, icon:'mdi-delete'}"
                  @on-agree="hapusItem(item)">
                </confirm-btn>
              </div>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogTambahAktivitasTambahan" max-width="600px" :eager="true">
      <v-card>
        <v-card-text>
          <v-form ref="formTambahAktivitasTambahan">
            <h1 class="display-2 my-2">Entri Manajemen Aktivitas Tambahan</h1>
            <v-row class="pt-5">
              <v-col>
                <v-autocomplete v-model="bank_aktivitas" :items="bankAktivitas" :loading="loadingBankAktivitas" label="Tambah Dari Bank Aktivitas" item-text="v" item-value="k" hide-details outlined dense></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field dense v-model="formEdit.nama_aktivitas" :rules="[rules.required]" hide-details outlined label="Nama Aktivitas"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete v-model="formEdit.tingkat_kesulitan" :items="tingkatKesulitan" label="Tingkat Kesulitan" item-text="v" item-value="v" hide-details outlined dense>
                  <template slot="item" slot-scope="data">
                    <v-list-item-content>
                      <v-list-item-title v-if="data.item.header">
                        <strong>{{ data.item.header }}</strong>
                      </v-list-item-title>
                      <v-divider v-else-if="data.item.divider" />
                      <v-list-item-sub-title v-html="data.item.v"></v-list-item-sub-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col>
                <v-text-field dense v-model="formEdit.satuan_output" :rules="[rules.required]" hide-details outlined label="Satuan Output"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field dense v-model="formEdit.waktu" :rules="[rules.required]" hide-details outlined label="Waktu"></v-text-field>
              </v-col>
              <v-col justify="center">Menit</v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogTambahAktivitasTambahan = false">Tutup</v-btn>
          <v-btn color="success" @click="simpanAktivitas()" :loading="loadingSimpanBankAktivitas">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash'
import KonfigurasiAktivitasService from '@/services/KonfigurasiAktivitasService'
import store from '@/store'
import _g from '../../global'
import axios from 'axios'
import {local} from '@/store/local'
import {defaultRules} from '@/utils/lib'
import ConfirmBtn from '@/components/base/ConfirmBtn'
import { HotTable } from '@handsontable/vue';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.css';

registerAllModules();

export default {
  components:{
    ConfirmBtn,
    HotTable
  },

  data(){
    return{
      forceUpdate:false,
      loading:false,
      detailData:{},
      headers:[
        {text:'Nomor', value:'no'},
        {text:'Kode Aktivitas', value:'kode_aktifitas'},
        {text:'Nama Aktivitas', value:'nama_aktifitas'},
        {text:'Satuan Output', value:'satuan_output'},
        {text:'Tingkat Kesulitan', value:'tingkat_kesulitan'},
        {text:'Waktu', value:'waktu'},
        {text:'', value:'aksi'},
      ],
      items:[],
      dialogReset:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        enableDetail:false,
        enableCreate:false,
      },
      rowPerPage:0,
      page:1,
      totalKeseluruhan:0,
      // bank_aktivitas: '',
      bankAktivitas:[],
      loadingBankAktivitas:false,
      search: '',
      tahun: null,
      dialogTambahAktivitasTambahan:false,
      formEdit:{},
      rules:{
        ...defaultRules
      },
      tingkatKesulitan: [{ header:'Mudah'},{v:'1.0'},{v:'1.1'},{v:'1.2'},{v:'1.3'},{v:'1.4'},{v:'1.5'},{ divider: true },{ header:'Sedang'},{v:'1.6'},{v:'1.7'},{v:'1.8'},{v:'1.9'},{v:'2.0'},{ divider: true },{ header:'Sulit'},{v:'2.1'},{v:'2.2'},{v:'2.3'},{v:'2.4'},{v:'2.5'},{v:'2.6'},{v:'2.7'},{v:'2.8'},{v:'2.9'},{v:'3.0'},{ divider: true }],
      loadingSimpanBankAktivitas:false,
      loadingSimpanEditTable:false,
      hotSettings:{
        data: [],
        licenseKey: "non-commercial-and-evaluation",
        columns: [
          {
            type: 'text',
            width: '100px',
          },
          {
            type: 'text',
            width: '400px',
          },
          {
            type: 'text',
            width: '150px',
          },
          {
            type: 'numeric',
            width: '100px',
            format: '0.0',
          },
          {
            type: 'numeric',
            width: '100px',
          },
          {
            type: 'numeric',
            width: '100px',
            numericFormat: {
              pattern: '0,0.00',
              culture: 'en-US'
            }
          },
        ],
        colHeaders: [
          'Kode Aktivitas',
          'Nama Aktivitas',
          'Satuan Output',
          'Tingkat Kesulitan',
          'Waktu (Menit)',
          'Angka Kredit'
        ],
        rowHeaders:true,
        stretchH:"all",
        height:500,
        minSpareRows: 1
      },
      editTable:false
    }
  },

  computed: {
    bank_aktivitas: {
      get() { return this.text; },
      set: _.debounce(function(newValue) {
        this.formEdit.bank_aktivitas = newValue
      }, 100)
    },
    baseUrl(){
      return process.env.VUE_APP_API_URL + '/api'
    },
    currentYear(){
      return store.state.user.current.year
    }
  },

  watch:{
    page(val){
      this.tableValue.page = val
      this.updateTable(this.tableValue)
    }
  },

  mounted(){
    this.$watch(vm => [vm.rowPerPage, vm.search, vm.selectSKPD, vm.selectBulan], () => {
      this.page = 1
      this.updateTable({itemsPerPage:this.rowPerPage, bulan:this.selectBulan, kode_skpd:this.selectSKPD, search:this.search, page:this.page})
    })
  },

  created(){
    var d = new Date()
    var m = d.getMonth()+1
    this.selectBulan = m
    let val = {itemsPerPage:10, page: 1}
    this.editTable = false
    this.updateTable(val)
    this.getBankAktivitas()
    this.tahun = this.$store.getters["user/data"].tahun
  },

  methods:{
    getImg(nip,ext){
      if (nip){
        let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip +"."+ext
        return avatarImgUrl
      }
      return ''
    },

    updateTable(val){
      this.loading = true
      this.tableValue = val ? val: this.tableValue
      this.items = []

      var url = _g.getBaseURLAPIERK(this.currentYear, "v1/konfigurasi-aktivitas/getAktivitasTambahan")
      this.dataHotTable = []
      axios.post(url,{
        api:1,
        search:this.tableValue.search,
        row_per_page:this.tableValue.itemsPerPage,
        page:this.tableValue.page
      }).then(response => {
        this.totalKeseluruhan = response.data.total_keseluruhan
        let res = response.data
        this.dataHotTable = res.dataHandsonTable
        this.$refs.hotTableComponent.hotInstance.updateSettings({
            data: this.dataHotTable,
            // beforeChange: this.beforeChangeFunc(),
            // beforeKeyDown: this.beforeKeyDownFunc(),
        });
        this.items = res.data
        if (res.status == 200){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Get Data Berhasil'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).finally(()=>{
        this.loading = false
      }).catch(err => {
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    edit(){
      if(this.editTable){
        this.editTable = false
      }else{
        this.editTable = true
      }
    },

    editItem(item){
      this.formEdit = {}
      this.formEdit = _.clone(item)
      this.formEdit.nama_aktivitas = item.nama_aktifitas
      this.dialogTambahAktivitasTambahan = true
    },

    hapusItem(item){
      const url = _g.getBaseURLAPIERK(this.currentYear, "v1/konfigurasi-aktivitas/hapusAktifitas")
      axios.post(url, {
        api:1,
        kode_aktifitas:item.kode_aktifitas
      }).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
        this.search = ''
        this.updateTable()
        this.dialogTambahAktivitasTambahan = false
      }).catch(err => {
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    exportExcel(){
      var current = this.$store.getters["user/current"]
      var bulan = this.selectBulan
      var kode_skpd = this.selectSKPD
      var year = current.year
      var urls = ''
      var base = process.env.VUE_APP_API_URL
      if(base === 'https://mangbagja-demo.rev.web.id/'){
        base = 'https://erk-demo.rev.web.id/'
      }else{
        base = 'https://kinerja.bandung.go.id/'
      }
      if(!kode_skpd){
        urls = base+year+"/api/v1/monitoring-aktivitas/export?api=1&bulan="+bulan
      }else{
        urls = base+year+"/api/v1/monitoring-aktivitas/export?api=1&bulan="+bulan+"&kolok="+kode_skpd
      }
      window.open(urls,'_blank');
    },

    tambah(){
      this.formEdit = {}
      this.dialogTambahAktivitasTambahan = true
    },

    getBankAktivitas(){
      this.loadingBankAktivitas = true
      const url = _g.getBaseURLAPIERK(this.currentYear, "v1/konfigurasi-aktivitas/getBankAktivitasTambahan")
      axios.post(url, {api:1}).then(response => {
        let data = []
        let ba = response.data.data
        _.each(ba, function(value, key){
          // data.push({k:key, v: value})
          data.push({k:value.id, v: value.text})
        })
        this.bankAktivitas = data
        this.loadingBankAktivitas = false
      }).catch(err => {
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    simpanAktivitas(){
      this.loadingSimpanBankAktivitas = true
      const url = _g.getBaseURLAPIERK(this.currentYear, "v1/konfigurasi-aktivitas/simpanTambahan")
      const data = {
        'kode_aktifitas': this.formEdit.kode_aktifitas,
        'kategori': this.formEdit.kategori,
        'nama_aktifitas': this.formEdit.nama_aktivitas,
        'satuan_output': this.formEdit.satuan_output,
        'tingkat_kesulitan': this.formEdit.tingkat_kesulitan,
        'waktu': this.formEdit.waktu,
        'nip': _g.getCurrentUsername()
      }
      axios.post(url, {
        api:1,
        ...data
      }).then(response => {
        let res = response.data
        if (res.status == 200){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Get Data Berhasil'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
        this.loadingSimpanBankAktivitas = false
        this.dialogTambahAktivitasTambahan = false
        this.updateTable()
      }).catch(err => {
        var error = err.message
        this.loadingSimpanBankAktivitas = false
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    simpanEditTable(){
      this.loadingSimpanEditTable = true
      const data = this.$refs.hotTableComponent.hotInstance.getData()
      const url = _g.getBaseURLAPIERK(this.currentYear, "v1/konfigurasi-aktivitas/saveJabatanTable")
      const username = _g.getCurrentUsername()
      axios.post(url, {
        api:1,
        data:data,
        nip:username
      }).then(response => {
        let res = response.data
        if (res.status == 200){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Get Data Berhasil'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
        this.loadingSimpanEditTable = false
        this.dialogTambahAktivitasTambahan = false
        this.updateTable()
      }).catch(err => {
        var error = err.message
        this.loadingSimpanEditTable = false
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.pegawai-table{
  tr,th,td {
    border: 1px solid #ddd;
  }
  thead tr th{
    color:white;
  }
}
.handsontableInputHolder {
  z-index: 9999;
}
</style>
  